import React from "react";
import styled from "styled-components";
import tw from "twin.macro";
//eslint-disable-next-line
import { css } from "styled-components/macro";
import { SectionHeading, Subheading as SubheadingBase } from "components/misc/Headings.js";
import { SectionDescription } from "components/misc/Typography.js";

import defaultCardImage from "images/shield-icon.svg";

import { ReactComponent as SvgDecoratorBlob3 } from "images/svg-decorator-blob-3.svg";

const Container = tw.div`relative`;

const ThreeColumnContainer = styled.div`
  ${tw`flex flex-row items-center md:items-stretch md:flex-row flex-wrap justify-center max-w-screen-lg mx-auto py-10 md:py-12`}
`;
const Subheading = tw(SubheadingBase)`mb-4 text-gold-100 text-center`;
const Heading = tw(SectionHeading)`w-full text-custom-red`;
const Description = tw(SectionDescription)`w-full text-center`;

const VerticalSpacer = tw.div`mt-10 w-full`;
const VerticalSpacerB = tw.div`mt-2 w-full`;

const Column = styled.div`
  ${tw`md:w-1/3 lg:w-1/4 sm:w-1/2 xs:w-1/3`}
`;

const Card = styled.div`
  ${tw`flex flex-row sm:flex-row items-center sm:items-center text-center sm:text-left h-full mx-4 px-3 py-4 justify-center`}
  .imageContainer {
    // ${tw`border text-center rounded-full p-5 flex-shrink-0`}
    ${tw`flex-shrink-0`}
    img {
      ${tw`md:w-40 lg:w-56 xs:w-24`}
    }
  }
`;

const ColumnB = styled.div`
  ${tw`md:w-1/4 lg:w-1/5 sm:w-1/3 xs:w-1/4`}
`;

const CardB = styled.div`
  ${tw`flex flex-row sm:flex-row items-center sm:items-center text-center sm:text-left h-full mx-4 px-3 py-4 justify-center`}
  .imageContainer {
    // ${tw`border text-center rounded-full p-5 flex-shrink-0`}
    ${tw`flex-shrink-0`}
    img {
      ${tw`md:w-32 lg:w-52 xs:w-20`}
    }
  }
`;

const DecoratorBlob = styled(SvgDecoratorBlob3)`
  ${tw`pointer-events-none absolute right-0 bottom-0 w-64 opacity-25 transform translate-x-32 translate-y-48 `}
`;

export default ({
  cards = null,
  heading = "Sponsors",
  subheading = "Sponsors",
  description = "Our event would not be possible without the support of these sponsors.",
}) => {
  /*
   * This componets has an array of object denoting the cards defined below. Each object in the cards array can have the key (Change it according to your need, you can also add more objects to have more cards in this feature component) or you can directly pass this using the cards prop:
   *  1) imageSrc - the image shown at the top of the card
   *  2) title - the title of the card
   *  3) description - the description of the card
   *  If a key for a particular card is not provided, a default value is used
   */

  const defaultCards = [
    // {
    //   imageSrc:
    //     "https://inccreativeresources.blob.core.windows.net/clients-assets/lanterns-on-the-awa/sponsors/_0012_08-Pacific-Helmets-Logo.png",
    //   title: "Sponsors",
    // },
    // {
    //   imageSrc:
    //     "https://inccreativeresources.blob.core.windows.net/clients-assets/lanterns-on-the-awa/sponsors/_0011_09-MD-logo.png",
    //   title: "Sponsors",
    // },
    {
      imageSrc:
        "https://inccreativeresources.blob.core.windows.net/clients-assets/lanterns-on-the-awa/sponsors/_0009_11-GJGardener.png",
      title: "Sponsors",
    },
    {
      imageSrc:
        "https://inccreativeresources.blob.core.windows.net/clients-assets/lanterns-on-the-awa/sponsors/_0010_10-Property-Brokers-Logo.png",
      title: "Sponsors",
    },
  ];

  // const defaultCardsB = [
  //   {
  //     imageSrc:
  //       "https://inccreativeresources.blob.core.windows.net/clients-assets/lanterns-on-the-awa/sponsors/_0000_sponsor-the-dentists.png",
  //     title: "Sponsors",
  //   },
  //   {
  //     imageSrc:
  //       "https://inccreativeresources.blob.core.windows.net/clients-assets/lanterns-on-the-awa/sponsors/_0001_sponsor-jarussell.png",
  //     title: "Sponsors",
  //   },
  // ];

  if (!cards) cards = defaultCards;

  return (
    <Container>
      <ThreeColumnContainer>
        {/* {subheading && <Subheading>{subheading}</Subheading>} */}
        <Heading>{heading}</Heading>
        {description && <Description>{description}</Description>}
        <VerticalSpacer />
        {cards.map((card, i) => (
          <Column key={i}>
            <Card>
              <span className="imageContainer">
                <img src={card.imageSrc || defaultCardImage} alt="" />
              </span>
            </Card>
          </Column>
        ))}
        {/* <VerticalSpacerB /> */}
        {/* {defaultCardsB.map((card, i) => (
          <ColumnB key={i}>
            <CardB>
              <span className="imageContainer">
                <img src={card.imageSrc} alt="" />
              </span>
            </CardB>
          </ColumnB>
        ))} */}
      </ThreeColumnContainer>
      <DecoratorBlob />
    </Container>
  );
};
