import React, { useState } from "react";
import { motion } from "framer-motion";
import styled from "styled-components";
import tw from "twin.macro";
import { css } from "styled-components/macro"; //eslint-disable-line
import { SectionHeading, Subheading as SubheadingBase } from "components/misc/Headings.js";
import { SectionDescription } from "components/misc/Typography.js";
import { Container, ContentWithPaddingXl } from "components/misc/Layouts.js";
import { ReactComponent as ChevronDownIcon } from "feather-icons/dist/icons/chevron-down.svg";
import { ReactComponent as SvgDecoratorBlob1 } from "images/svg-decorator-blob-7.svg";
import { ReactComponent as SvgDecoratorBlob2 } from "images/svg-decorator-blob-8.svg";
import LuckyDrawForm from "./LuckyDrawForm";

const Subheading = tw(SubheadingBase)`mb-4 text-center`;
const Heading = tw(SectionHeading)`w-full`;
const Description = tw(SectionDescription)`w-full text-center`;

const Column = tw.div`flex flex-col items-center`;
const HeaderContent = tw.div`flex flex-col items-center`;

const FAQSContainer = tw.dl`mt-12 max-w-4xl relative`;
const FAQ = tw.div`cursor-pointer select-none mt-5 px-8 sm:px-10 py-5 sm:py-4 rounded-lg text-gray-800 hover:text-gray-900 bg-gray-200 hover:bg-gray-300 transition duration-300`;
const Question = tw.dt`flex justify-between items-center`;
const QuestionText = tw.span`text-lg lg:text-xl font-semibold`;
const QuestionToggleIcon = motion(styled.span`
  ${tw`ml-2 transition duration-300`}
  svg {
    ${tw`w-6 h-6`}
  }
`);
const Answer = motion(tw.dd`pointer-events-none text-xs sm:text-base leading-relaxed text-left`);

const DecoratorBlob1 = styled(SvgDecoratorBlob1)`
  ${tw`pointer-events-none -z-20 absolute right-0 top-0 h-56 w-56 opacity-15 transform translate-x-2/3 -translate-y-12 text-teal-400`}
`;
const DecoratorBlob2 = styled(SvgDecoratorBlob2)`
  ${tw`pointer-events-none -z-20 absolute left-0 bottom-0 h-64 w-64 opacity-15 transform -translate-x-2/3 text-primary-500`}
`;

const Tncul = tw.ul``;
const tncStyle = {
  listStyleType: "disc",
};

export default ({
  subheading = "A Date with Lanterns on the Awa",
  heading = "Lucky Draw Competition",
  description = "Prize: $300 cash, equivalent to one-night motel stay and additional spending money.",
  faqs = [
    {
      question: "Terms & Conditions",
      tnc_1: "This is a straightforward online competition.",
      tnc_2:
        "The objective is to determine the number of out-of-town visitors attending our event from areas outside the Whanganui region and their respective origins.",
      tnc_3:
        "We will collect minimal personal information and securely dispose of it after the event.",
      tnc_4: "Our server is hosted on Microsoft Azure, ensuring a sense of security.",
      tnc_5: "The draw will occur at the start of session two on the night, approximately 7:45 PM.",
      tnc_6: "Participants must be physically present on-site to claim the prize.",
      tnc_7:
        "Proof of identity demonstrating the winner's residency outside the Whanganui region is mandatory.",
      tnc_8:
        "The event director's decision is conclusive, and no further correspondence will be entertained.",
      tnc_9: "Prizes cannot be exchanged.",
      tnc_10:
        "LotA reserves the right to disqualify any participants found in violation of the rules and regulations.",
      tnc_11:
        "LotA reserves the right to cancel, terminate, or suspend the competition with or without prior notice and/or providing any reason.",
      tnc_12:
        "LotA reserves the right to modify, amend, delete, or append to the program Terms and Conditions without prior notice at any time.",
    },
  ],
}) => {
  const [activeQuestionIndex, setActiveQuestionIndex] = useState(null);

  const toggleQuestion = (questionIndex) => {
    if (activeQuestionIndex === questionIndex) setActiveQuestionIndex(null);
    else setActiveQuestionIndex(questionIndex);
  };

  return (
    <Container id="competition2024">
      <ContentWithPaddingXl>
        <Column>
          <HeaderContent>
            {subheading && <Subheading>{subheading}</Subheading>}
            <Heading>{heading}</Heading>
            {description && <Description>{description}</Description>}
          </HeaderContent>
          <LuckyDrawForm />
          <FAQSContainer>
            {faqs.map((faq, index) => (
              <FAQ
                key={index}
                onClick={() => {
                  toggleQuestion(index);
                }}
                className="group"
              >
                <Question>
                  <QuestionText>{faq.question}</QuestionText>
                  <QuestionToggleIcon
                    variants={{
                      collapsed: { rotate: 0 },
                      open: { rotate: -180 },
                    }}
                    initial="collapsed"
                    animate={activeQuestionIndex === index ? "open" : "collapsed"}
                    transition={{ duration: 0.02, ease: [0.04, 0.62, 0.23, 0.98] }}
                  >
                    <ChevronDownIcon />
                  </QuestionToggleIcon>
                </Question>
                <Answer
                  variants={{
                    open: { opacity: 1, height: "auto", marginTop: "16px" },
                    collapsed: { opacity: 0, height: 0, marginTop: "0px" },
                  }}
                  initial="collapsed"
                  animate={activeQuestionIndex === index ? "open" : "collapsed"}
                  transition={{ duration: 0.3, ease: [0.04, 0.62, 0.23, 0.98] }}
                >
                  <Tncul style={tncStyle}>
                    <li>{faq.tnc_1}</li>
                    <li>{faq.tnc_2}</li>
                    <li>{faq.tnc_3}</li>
                    <li>{faq.tnc_4}</li>
                    <li>{faq.tnc_5}</li>
                    <li>{faq.tnc_6}</li>
                    <li>{faq.tnc_7}</li>
                    <li>{faq.tnc_8}</li>
                    <li>{faq.tnc_9}</li>
                    <li>{faq.tnc_10}</li>
                    <li>{faq.tnc_11}</li>
                    <li>{faq.tnc_12}</li>
                  </Tncul>
                </Answer>
              </FAQ>
            ))}
          </FAQSContainer>
        </Column>
      </ContentWithPaddingXl>
      <DecoratorBlob1 />
      <DecoratorBlob2 />
    </Container>
  );
};
