import React from "react";
import styled from "styled-components";
import tw from "twin.macro";
//eslint-disable-next-line
import { css } from "styled-components/macro";
import { SectionHeading, Subheading as SubheadingBase } from "components/misc/Headings.js";
import { SectionDescription } from "components/misc/Typography.js";

import defaultCardImage from "images/shield-icon.svg";

import { ReactComponent as SvgDecoratorBlob } from "images/svg-decorator-blob-6.svg";

const Container = tw.div`relative`;

const ThreeColumnContainer = styled.div`
  ${tw`flex flex-row items-center md:items-stretch md:flex-row flex-wrap justify-center max-w-screen-lg mx-auto py-20 md:py-24`}
`;
const Subheading = tw(SubheadingBase)`mb-4 text-gold-100 text-center`;
const Heading = tw(SectionHeading)`w-full text-custom-red`;
const Description = tw(SectionDescription)`w-full text-center`;

const VerticalSpacer = tw.div`mt-10 w-full`;

const Column = styled.div`
  ${tw`max-w-sm`}
`;

const Card = styled.div`
  ${tw`flex flex-row sm:flex-row items-center sm:items-center text-center sm:text-left h-full mx-4 px-2 py-8 justify-center`}
  .imageContainer {
    // ${tw`border text-center rounded-full p-5 flex-shrink-0`}
    ${tw`flex-shrink-0`}
    img {
      ${tw`xs:w-72 w-96 md:w-80`}
    }
  }
`;

const DecoratorBlob = styled(SvgDecoratorBlob)`
  ${tw`pointer-events-none -z-20 absolute left-0 bottom-0 h-64 w-64 opacity-25 transform -translate-x-1/2 translate-y-1/2`}
`;

export default ({
  cards = null,
  heading = "Title Sponsor",
  subheading = "Sponsors",
  description = "This event is supported by our local business - GDM Retail Systems - a world class designer and manufacturer of innovative retail display solutions, fixtures and store shelving for leading retailers. ",
}) => {
  /*
   * This componets has an array of object denoting the cards defined below. Each object in the cards array can have the key (Change it according to your need, you can also add more objects to have more cards in this feature component) or you can directly pass this using the cards prop:
   *  1) imageSrc - the image shown at the top of the card
   *  2) title - the title of the card
   *  3) description - the description of the card
   *  If a key for a particular card is not provided, a default value is used
   */

  const defaultCards = [
    {
      imageSrc:
        "https://inccreativeresources.blob.core.windows.net/clients-assets/lanterns-on-the-awa/sponsors/gdm-logo-hor.svg",
      title: "GDM",
      url: "https://gdmretail.com/",
    },
    // { imageSrc: SupportIconImage, title: "24/7 Support" },
    // { imageSrc: CustomizeIconImage, title: "Customizable" },
    // { imageSrc: ReliableIconImage, title: "Reliable" },
    // { imageSrc: FastIconImage, title: "Fast" },
    // { imageSrc: SimpleIconImage, title: "Easy" },
  ];

  if (!cards) cards = defaultCards;

  return (
    <Container id="sponsors">
      <ThreeColumnContainer>
        {subheading && <Subheading>{subheading}</Subheading>}
        <Heading>{heading}</Heading>
        {description && <Description>{description}</Description>}
        <VerticalSpacer />
        {cards.map((card, i) => (
          <Column key={i}>
            <Card>
              <span className="imageContainer">
                <a href={card.url} target="_blank">
                  <img src={card.imageSrc || defaultCardImage} alt="" />
                </a>
              </span>
            </Card>
          </Column>
        ))}
      </ThreeColumnContainer>
      <DecoratorBlob />
    </Container>
  );
};
