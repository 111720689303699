import React from "react";
import styled from "styled-components";
import tw from "twin.macro";
import { css } from "styled-components/macro"; //eslint-disable-line
import { ReactComponent as SvgDotPatternIcon } from "../../images/dot-pattern.svg";
import loading from "../../images/loading-7528_256.gif";

const Container = tw.div`fixed w-screen h-screen z-50 bg-black80 top-0 left-0 pointer-events-none`;
const Content = tw.div`max-w-screen-xl mx-auto py-20 lg:py-24 flex justify-center items-center h-screen`;

const imgStyle = {
  width: "5%",
};

export default () => {
  return (
    <Container>
      <Content>
        <img src={loading} style={imgStyle}></img>
      </Content>
    </Container>
  );
};
