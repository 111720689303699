import React, { useState } from "react";
import styled from "styled-components";
import tw from "twin.macro";
import { css } from "styled-components/macro"; //eslint-disable-line
import { ReactComponent as SvgDotPatternIcon } from "../../images/dot-pattern.svg";
import LoadingComp from "./LoadingComp";

const Container = tw.div`relative`;
const Content = tw.div`max-w-screen-xl mx-auto py-20 lg:py-24`;

const FormContainer = styled.div`
  ${tw`p-10 sm:p-12 md:p-16 bg-primary-500 text-gray-100 rounded-lg relative`}
  form {
    ${tw`mt-4`}
  }
  h2 {
    ${tw`text-3xl sm:text-4xl font-bold`}
  }
  input,
  textarea {
    ${tw`w-full bg-transparent text-gray-100 text-base font-medium tracking-wide border-b-2 py-2 text-gray-100 hocus:border-pink-400 focus:outline-none transition duration-200`};

    ::placeholder {
      ${tw`text-gray-500`}
    }
  }
`;

const SingleColumn = tw.div`flex flex-row sm:flex-row justify-between w-full`;
const ColumnB = tw.div`sm:w-full flex flex-row w-full`;
const InputContainerB = tw.div`relative mt-4 ml-0 text-xs`;
const InputB = tw.input`w-autoi mr-3`;

const Select = tw.select`text-gray-800 mt-5 rounded`;
const Option = tw.option``;

const TwoColumn = tw.div`flex flex-col sm:flex-row justify-between`;
const Column = tw.div`sm:w-5/12 flex flex-col`;
const InputContainer = tw.div`relative py-5 mt-6`;
const Label = tw.label`absolute top-0 left-0 tracking-wide font-semibold text-sm`;
const Input = tw.input``;
const TextArea = tw.textarea`h-24 sm:h-full resize-none`;
const SubmitButton = tw.button`w-full sm:w-32 mt-6 py-3 bg-gray-100 text-primary-500 rounded-full font-bold tracking-wide shadow-lg uppercase text-sm transition duration-300 transform focus:outline-none focus:shadow-outline hover:bg-gray-300 hover:text-primary-700 hocus:-translate-y-px hocus:shadow-xl`;

const SvgDotPattern1 = tw(
  SvgDotPatternIcon
)`absolute bottom-0 right-0 transform translate-y-1/2 translate-x-1/2 -z-10 opacity-50 text-primary-500 fill-current w-24`;

// const postApi = "http://localhost:27018/lanternsontheawa/users/new"; // local dev/testing
const postApi =
  "https://api-liondev-lanterns-on-the-awa-api.azurewebsites.net/lanternsontheawa/users/new"; // local dev/testing

export default () => {
  const [firstname, setFirstname] = useState();
  const [lastname, setLastname] = useState();
  const [email, setEmail] = useState();
  const [region, setRegion] = useState();

  const [startLoading, setStartLoading] = useState(false);

  const submitHandler = async (e) => {
    e.preventDefault();
    setStartLoading(true);
    console.log(`Firstname: ${firstname}`);
    console.log(`Lastname: ${lastname}`);
    console.log(`Email: ${email}`);
    console.log(`Region: ${region}`);

    let userData = {
      firstname: firstname,
      lastname: lastname,
      email: email,
      region: region,
    };

    const result = await fetch(postApi, {
      method: "post",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(userData),
    });

    const json = await result.json();
    // if (json.message == "true" || json.message == true) {
    //   alert("Thank you, you have entered the competition!");
    // } else {
    //   alert("Please try again.");
    // }

    switch (json.message) {
      case "true":
      case true:
        alert("Thank you, you have entered the competition!");
        break;
      case "exist":
      case "exists":
        alert("Great news! This email address has already entered the competition!");
        break;
    }

    window.scrollTo(0, 0);
    setStartLoading(false);
    window.location.reload();
  };

  return (
    <Container>
      {!startLoading ? null : <LoadingComp />}
      <Content>
        <FormContainer>
          <div tw="mx-auto max-w-4xl">
            <h2>Enter The Competition</h2>
            <form onSubmit={(e) => submitHandler(e)}>
              <TwoColumn>
                <Column>
                  <InputContainer>
                    <Label htmlFor="firstname-input">First Name</Label>
                    <Input
                      id="firstname-input"
                      type="text"
                      name="firstname"
                      placeholder="E.g. John"
                      onChange={(e) => setFirstname(e.target.value)}
                      required
                    />
                  </InputContainer>
                  <InputContainer>
                    <Label htmlFor="lastname-input">Last Name</Label>
                    <Input
                      id="lastname-input"
                      type="text"
                      name="lastname"
                      placeholder="E.g. Doe"
                      onChange={(e) => setLastname(e.target.value)}
                      required
                    />
                  </InputContainer>
                </Column>
                <Column>
                  <InputContainer tw="flex-1">
                    <Label htmlFor="email">Your E-Mail</Label>
                    <Input
                      id="email-input"
                      type="email"
                      name="email"
                      placeholder="E.g. john@mail.com"
                      onChange={(e) => setEmail(e.target.value)}
                      required
                    />
                  </InputContainer>
                  <InputContainer tw="flex-1">
                    <Label htmlFor="regions">Your Region</Label>
                    <Select
                      defaultValue=""
                      id="regions"
                      name="regions"
                      onChange={(e) => setRegion(e.target.value)}
                      required
                    >
                      <Option value="" disabled>
                        Select your option
                      </Option>
                      <Option value="Northland">Northland</Option>
                      <Option value="Auckland">Auckland</Option>
                      <Option value="Waikato">Waikato</Option>
                      <Option value="Bay of Plenty">Bay of Plenty</Option>
                      <Option value="Gisborne">Gisborne</Option>
                      <Option value="Hawke's Bay">Hawke's Bay</Option>
                      <Option value="Taranaki">Taranaki</Option>
                      {/* <Option value="Manawatū-Whanganui">Manawatū-Whanganui</Option> */}
                      <Option value="Wellington">Wellington</Option>
                      <Option value="Tasman">Tasman</Option>
                      <Option value="Nelson">Nelson</Option>
                      <Option value="Marlborough">Marlborough</Option>
                      <Option value="West Coast">West Coast</Option>
                      <Option value="Canterbury">Canterbury</Option>
                      <Option value="Otago">Otago</Option>
                      <Option value="Southland">Southland</Option>
                    </Select>
                  </InputContainer>
                </Column>
              </TwoColumn>
              <SubmitButton type="submit" value="Submit">
                Submit
              </SubmitButton>
              <SingleColumn>
                <ColumnB>
                  <InputContainerB>
                    <InputB id="tnc" type="checkbox" name="tnc" defaultChecked required />I agree
                    the terms & conditions.
                  </InputContainerB>
                </ColumnB>
              </SingleColumn>
            </form>
          </div>
          <SvgDotPattern1 />
        </FormContainer>
      </Content>
    </Container>
  );
};
