import React from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import Hero from "components/hero/BackgroundAsImageWithCenteredContent.js";
// import Testimonial from "components/testimonials/TwoColumnWithImage.js";
import Gallery from "components/gallery/Gallery";
import Gallery2022 from "components/gallery/Gallery2022";
import Footer from "components/footers/LotAFooter.js";
// import Features from "components/features/ThreeColWithSideImage";
import TitleSponsor from "components/sponsors/TitleSponsor";
import Organizers from "components/sponsors/Organizers";
import Sponsors from "components/sponsors/Sponsors";
import Contributors from "components/sponsors/Contributors";
// import LanternsMaking from "components/activity/LanternsMaking";
import LanternPoster from "components/hero/LanternPoster";
import EventInfo from "components/hero/EventInfo";

//-------------- 2024 -------------------
import LuckyDrawDetails from "components/custom/LuckyDrawDetails";
import AboutLotA from "components/custom/AboutLotA";

export default () => (
  <AnimationRevealPage disabled>
    <LanternPoster />
    <AboutLotA />
    <EventInfo />
    {/* <LuckyDrawDetails /> */}
    {/* <Hero /> */}
    <Gallery2022 />
    <Gallery />
    {/* <LanternsMaking /> */}
    <TitleSponsor />
    <Organizers />
    <Sponsors />
    <Contributors />
    <Footer />
  </AnimationRevealPage>
);
