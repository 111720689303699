import React from "react";
import tw from "twin.macro";
import styled from "styled-components";
import { Container as ContainerBase } from "components/misc/Layouts.js";
import logo from "../../images/logo.svg";
// import title from "../../images/logo-title.svg";
import title from "../../images/title-logo.png";
import { ReactComponent as FacebookIcon } from "../../images/facebook-icon.svg";
import { ReactComponent as TwitterIcon } from "../../images/twitter-icon.svg";
import { ReactComponent as YoutubeIcon } from "../../images/youtube-icon.svg";

const Container = tw(ContainerBase)`bg-gray-900 text-gray-100 -mx-8 -mb-8`;
const Content = tw.div`max-w-screen-xl mx-auto py-20 lg:py-24`;

const Row = tw.div`flex items-center justify-center flex-col px-8`;

const LogoContainer = tw.div`flex items-center justify-center md:justify-start sm:w-1/2 xs:w-4/5`;
const LogoImg = tw.img`w-full`;
// const LogoText = tw.h5`ml-2 text-2xl font-black tracking-wider`;

// const LinksContainer = tw.div`mt-8 font-medium flex flex-wrap justify-center items-center flex-col sm:flex-row`;
const Link = tw.a`border-b-2 border-transparent hocus:text-gray-300 hocus:border-gray-300 pb-1 transition duration-300 mt-2 mx-4`;

const SocialLinksContainer = tw.div`mt-10`;
const SocialLink = styled.a`
  ${tw`cursor-pointer inline-block text-gray-100 hover:text-gray-500 transition duration-300 mx-4`}
  svg {
    ${tw`w-5 h-5`}
  }
`;

const CopyrightText = tw.p`text-center mt-10 font-medium tracking-wide text-sm text-gray-600`;

// const Column = tw.div`w-1/2 md:w-1/5 mb-8 md:mb-0 text-sm sm:text-base text-center md:text-left`;
const Column = tw.div`w-full text-sm sm:text-base text-center py-10`;
const ColumnHeading = tw.h5`font-bold uppercase`;
const LinkList = tw.ul`mt-4 text-sm font-medium`;
const LinkListItem = tw.li`mt-3`;
const footerYear = new Date().getFullYear();

export default () => {
  return (
    <Container>
      <Content>
        <Row>
          <LogoContainer>
            <LogoImg src={title} />
          </LogoContainer>
          {/* <LinksContainer>
            <Link href="#">Home</Link>
            <Link href="#">About</Link>
            <Link href="#">Contact Us</Link>
            <Link href="#">Blog</Link>
            <Link href="#">Reviews</Link>
          </LinksContainer> */}
          <Column>
            <ColumnHeading>Contact</ColumnHeading>
            <LinkList>
              <LinkListItem>+64 21 140 7607</LinkListItem>
              <LinkListItem>
                <Link href="mailto:lim@inccreative.co.nz">lim@inccreative.co.nz</Link>
              </LinkListItem>
              <LinkListItem>
                <Link href="https://www.google.com/maps/place/Inc+Creative/@-39.9348001,175.0471585,19z/data=!3m1!4b1!4m5!3m4!1s0x6d4003be5e9263c9:0xc4ef41884c96d822!8m2!3d-39.9348001!4d175.0477057">
                  53D Wilson Street, Whanganui 4500, New Zealand
                </Link>
              </LinkListItem>
              {/* <LinkListItem>
                <Link href="#">Report Abuse</Link>
              </LinkListItem> */}
            </LinkList>
          </Column>
          {/* <SocialLinksContainer>
            <SocialLink href="https://facebook.com">
              <FacebookIcon />
            </SocialLink>
            <SocialLink href="https://twitter.com">
              <TwitterIcon />
            </SocialLink>
            <SocialLink href="https://youtube.com">
              <YoutubeIcon />
            </SocialLink>
          </SocialLinksContainer> */}
          <CopyrightText>
            &copy; Copyright {footerYear}, INC Creative. All Rights Reserved.
          </CopyrightText>
        </Row>
      </Content>
    </Container>
  );
};
