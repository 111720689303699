import React from "react";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line
// import mainImg from "../../images/main-img.jpg";
// import mainImgGdm from "../../images/main-img-gdm.jpg";

import Header, {
  NavLink,
  PrimaryLink as PrimaryLinkBase,
  LogoLink,
  NavToggle,
  DesktopNavLinks,
} from "../headers/light.js";

const StyledHeader = styled(Header)`
  ${tw`pt-8 max-w-none w-full`}
  ${DesktopNavLinks} ${NavLink}, ${LogoLink} {
    ${tw`text-gdm-black-100 hover:text-gold-200`}
  }
  ${NavToggle}.closed {
    ${tw`text-gray-100 hover:text-primary-500`}
  }
`;

// const PrimaryLink = tw(PrimaryLinkBase)`rounded-full`;
// const Container2 = styled.div`
//   ${tw`relative -mx-8 -mt-8 bg-center bg-cover h-screen min-h-144`}
//   background-image: url("https://images.unsplash.com/photo-1536300007881-7e482242baa5?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1920&q=80");
// `;
const Container = styled.div`
  ${tw`relative -mx-8 bg-center bg-cover h-full overflow-hidden`}
  background-image: url("./han-mengqi-07mnVwbZ6-A-unsplash.jpg");
`;

const OpacityOverlay = tw.div`z-10 absolute inset-0 bg-black opacity-75`;

const HeroContainer = tw.div`z-20 relative px-6 sm:px-8 mx-auto h-full flex flex-col items-center justify-center`;
const Content = tw.div`px-4 flex flex-1 flex-col justify-center items-center`;

const Heading = styled.h1`
  span {
    ${tw`text-gdm-yellow`}
  }
  ${tw`text-2xl text-center sm:text-2xl lg:text-2xl xl:text-2xl font-black text-white leading-snug p-20 py-32 `}
`;

const PrimaryAction = tw.button`rounded-full px-8 py-3 mt-10 text-sm sm:text-base sm:mt-16 sm:px-8 sm:py-4 bg-gray-100 font-bold shadow transition duration-300 bg-gold-100 text-gray-100 hocus:bg-gold-200 hocus:text-gray-200 focus:outline-none focus:shadow-outline`;

const Title = styled.div`
  ${tw`flex items-center justify-center font-black border-b-0 text-2xl! ml-0! top-0 absolute w-full`};
`;

// const TitleText = styled.div`
//   ${tw`absolute h-screen mt-10 xs:w-120 lg:w-8/12 xl:w-7/12 2xl:w-8/12 3xl:w-7/12 max-w-1000 top-0 flex items-center justify-center z-10`}

//   img {
//     ${tw`xs:w-full xs:ml-20 sm:ml-20 w-10/12 ml-24`}
//   }
// `;

// const TitleGraphic = styled.div`
//   ${tw`absolute h-screen xs:w-130 lg:w-8/12 xl:w-7/12 2xl:w-8/12 3xl:w-7/12 max-w-1500 top-0 flex items-center justify-center`}

//   img {
//     ${tw`xs:ml-0 sm:ml-0 w-10/12 ml-24 mt-12 overflow-hidden`}
//   }
// `;

export default () => {
  return (
    <Container id="about">
      <OpacityOverlay />
      <Title>
        {/* <TitleText>
          <img src="https://inccreativeresources.blob.core.windows.net/clients-assets/lanterns-on-the-awa/main-title-2024.png" />
        </TitleText> */}
        {/* <TitleGraphic>
          <img src="https://inccreativeresources.blob.core.windows.net/clients-assets/lanterns-on-the-awa/lantern.png" />
        </TitleGraphic> */}
      </Title>
      <HeroContainer>
        {/* <StyledHeader links={navLinks} /> */}
        <Content>
          <Heading>
            <span>Lanterns on the Awa:</span> A cultural extravaganza by our beautiful river.
            Experience mesmerizing performances, delectable food, and community spirit. Don't miss
            this unforgettable night!
            {/* <br /> */}
            {/* 5pm - 9.45pm */}
            {/* <br /> */}
            {/* <span>7 Taupo Quay, Whanganui 4500</span> */}
          </Heading>
          {/* <PrimaryAction onClick={() => window.open("./Programme-2024.pdf", "_blank")}>
            Explore Programmes
          </PrimaryAction> */}
        </Content>
      </HeroContainer>
    </Container>
  );
};
